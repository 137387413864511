import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Home2 = () => {
  return (
    <main>
        <Header/>
        {/* <!-- Hero area S t a r t--> */}
        <section class="hero-area-two hero-area-bg">
            <div class="container">
                <div class="row g-4">
                    <div class="col-xl-7">
                        {/* <!-- hero slider --> */}
                        <div class="hero-slider-two swiper heroSwiperTwo-active wow fadeInUp" data-wow-delay="0.0s">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <div class="d-flex">
                                        <div class="slider-content">
                                            <div class="discount-badge-two">
                                                <span class="percentage">30% Discount</span>
                                            </div>
                                            <div class="hero-caption-two">
                                                <h4 class="title">New Stylish
                                                    Decor Furniture</h4>
                                                <p class="pera">Unique Furniture Style Design for Your Family</p>
                                            </div>
                                            <a href="shop.html" class="pill-btn-secondary">Shop Now</a>
                                        </div>
                                        <div class="hero-banner-two">
                                            <img class="banner-img" src="assets/images/hero/hero-banner-2.png" alt="img"/>
                                            <div class="banner-shape">
                                                <img src="assets/images/hero/hero-banner-shape.png" alt="img"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="d-flex">
                                        <div class="slider-content">
                                            <div class="discount-badge-two">
                                                <span class="percentage">50% Discount</span>
                                            </div>
                                            <div class="hero-caption-two">
                                                <h4 class="title">Luxury Living Room</h4>
                                                <p class="pera">Elevate Your Living Space with Elegant Designs</p>
                                            </div>
                                            <a href="shop.html" class="pill-btn-secondary">Explore Now</a>
                                        </div>
                                        <div class="hero-banner-two">
                                            <img class="banner-img" src="assets/images/hero/hero-banner-3.png" alt="img"/>
                                            <div class="banner-shape">
                                                <img src="assets/images/hero/hero-banner-shape.png" alt="img"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="d-flex">
                                        <div class="slider-content">
                                            <div class="discount-badge-two">
                                                <span class="percentage">70% Discount</span>
                                            </div>
                                            <div class="hero-caption-two">
                                                <h4 class="title">Modern Furniture</h4>
                                                <p class="pera">Create Your Dream Bedroom </p>
                                            </div>
                                            <a href="shop.html" class="pill-btn-secondary">Discover More</a>
                                        </div>
                                        <div class="hero-banner-two">
                                            <img class="banner-img" src="assets/images/hero/hero-banner-4.png" alt="img"/>
                                            <div class="banner-shape">
                                                <img src="assets/images/hero/hero-banner-shape.png" alt="img"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="swiper-pagination"></div>
                        </div>
                    </div>
                    <div class="col-xl-5">
                        <div class="row g-4">
                            <div class="col-xl-12 col-lg-6">
                                <div class="side-banner-two side-banner-bg-one wow fadeInUp" data-wow-delay="0.1s">
                                    <div class="slider-content">
                                        <div class="discount-badge-two">
                                            <span class="percentage">Mega Offer 36% Off</span>
                                        </div>
                                        <div class="hero-caption-two">
                                            <h4 class="title">The Latest Collection
                                                Of Furniture</h4>
                                            <div class="price-section">
                                                <h4 class="price discounted">$350</h4>
                                                <h4 class="price">$280</h4>
                                            </div>
                                        </div>
                                        <a href="shop.html" class="shop-link">Shop Now</a>
                                    </div>
                                    <div class="hero-side-banner-two">
                                        <img class="side-banner-img" src="assets/images/hero/side-banner-1.png" alt="img"/>
                                        <div class="banner-shape-two">
                                            <img src="assets/images/hero/side-banner-shape-1.png" alt="img"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-6">
                                <div class="side-banner-two side-banner-bg-two wow fadeInUp" data-wow-delay="0.3s">
                                    <div class="slider-content">
                                        <div class="discount-badge-two">
                                            <span class="percentage">Exclusive Offer 50% Off</span>
                                        </div>
                                        <div class="hero-caption-two">
                                            <h4 class="title">Farmaart
                                                Wooden Chaire</h4>
                                            <div class="price-section">
                                                <h4 class="price discounted">$500</h4>
                                                <h4 class="price">$250</h4>
                                            </div>
                                        </div>
                                        <a href="shop.html" class="shop-link">Shop Now</a>
                                    </div>
                                    <div class="hero-side-banner-two">
                                        <img class="side-banner-img" src="assets/images/hero/side-banner-2.png" alt="img"/>
                                        <div class="banner-shape-two">
                                            <img src="assets/images/hero/side-banner-shape-2.png" alt="img"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hero-bottom-section">
                    <div class="hero-bottom-card">
                        <div class="offer-img">
                            <img src="assets/images/hero/hero-bottom.png" alt="img"/>
                        </div>
                        <div class="offer-content">
                            <p class="pera">Latest Offer</p>
                            <div class="heading">
                                <h4 class="shape-title">40% off</h4>
                                <h4 class="title">only this Friday and get
                                    special gift</h4>
                            </div>
                            <a href="shop.html" class="pill-btn-primary-sm">Shop Now</a>
                        </div>
                    </div>
                    <div class="counting-section">
                        <div class="counting-category wow fadeInUp" data-wow-delay="0.0s">
                            <span class="outline-text">975+</span>
                            <h4 class="counting-title">Home Furniture</h4>
                        </div>
                        <div class="counting-category wow fadeInUp" data-wow-delay="0.1s">
                            <span class="outline-text">320+</span>
                            <h4 class="counting-title">Interior Design</h4>
                        </div>
                        <div class="counting-category wow fadeInUp" data-wow-delay="0.2s">
                            <span class="outline-text">980+</span>
                            <h4 class="counting-title">Happy Clients</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End-of Hero--> */}

        {/* <!-- Goal area S t a r t --> */}
        <section class="goal-area-two position-relative">
            <div class="container">
                <div class="goal-divider">
                    <div class="row g-4">
                        <div class="col-xxl-3 col-md-4 col-sm-6">
                            <div class="goal-card-two wow fadeInUp" data-wow-delay="0.0s">
                                <div class="circle-icon-two">
                                    <img src="assets/images/goal/icon-5.png" alt="img"/>
                                </div>
                                <a href="javascript:void(0)">
                                    <h4 class="title line-clamp-1 text-color-primary">Free Shipping</h4>
                                    <p class="pera text-color-tertiary line-clamp-2">Free shipping on all US order or
                                        order above $100</p>
                                </a>
                            </div>
                        </div>
                        <div class="col-xxl-3 col-md-4 col-sm-6">
                            <div class="goal-card-two wow fadeInUp" data-wow-delay="0.1s">
                                <div class="circle-icon-two">
                                    <img src="assets/images/goal/icon-6.png" alt="img"/>
                                </div>
                                <a href="javascript:void(0)">
                                    <h4 class="title line-clamp-1 text-color-primary">Security Payment</h4>
                                    <p class="pera text-color-tertiary line-clamp-2">Simply return it within 30 days for
                                        an exchange.</p>
                                </a>
                            </div>
                        </div>
                        <div class="col-xxl-3 col-md-4 col-sm-6">
                            <div class="goal-card-two wow fadeInUp" data-wow-delay="0.2s">
                                <div class="circle-icon-two">
                                    <img src="assets/images/goal/icon-7.png" alt="img"/>
                                </div>
                                <a href="javascript:void(0)">
                                    <h4 class="title line-clamp-1 text-color-primary">14-Day Return</h4>
                                    <p class="pera text-color-tertiary line-clamp-2">Simply return it within 30 days for
                                        an exchange.</p>
                                </a>
                            </div>
                        </div>
                        <div class="col-xxl-3 col-md-4 col-sm-6">
                            <div class="goal-card-two wow fadeInUp" data-wow-delay="0.3s">
                                <div class="circle-icon-two">
                                    <img src="assets/images/goal/icon-8.png" alt="img"/>
                                </div>
                                <a href="javascript:void(0)">
                                    <h4 class="title line-clamp-1 text-color-primary">24/7 Support</h4>
                                    <p class="pera text-color-tertiary line-clamp-2">Contact us 24 hours a day, 7 day’s
                                        week</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End-of goal--> */}

        {/* <!-- Custom area S t a r t --> */}
        <section class="custom-area bottom-padding1">
            <div class="container">
                <div class="row">
                    <div class="col-xl-7">
                        <div class="section-with-button">
                            <div class="section-title position-relative">
                                <h4 class="title">One Stop Custom and Factory
                                    Furniture Shopping</h4>
                                <div class="shape routedOne">
                                    <img src="assets/images/icon/title-shape.png" alt="img"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-4">
                    <div class="col-xl-7">
                        <div class="row g-4">
                            <div class="col-xl-5 col-md-6">
                                <div class="custom-img-gallery h-calc wow fadeInUp" data-wow-delay="0.0s">
                                    <div class="gallery-content">
                                        <h4 class="title">Bedroom Side Table</h4>
                                        <p class="pera">Up to <span class="highlights">40%</span> off Item</p>
                                        <a href="shop.html" class="shop-link">Shop Now</a>
                                    </div>
                                    <img src="assets/images/custom/custom-1.png" alt="img"/>
                                </div>
                            </div>
                            <div class="col-xl-7 col-md-6">
                                <div class="custom-img-gallery h-calc wow fadeInUp" data-wow-delay="0.2s">
                                    <div class="gallery-content">
                                        <h4 class="title">Sofa in White Room</h4>
                                        <p class="pera">Up to <span class="highlights">50%</span> off Item</p>
                                        <a href="shop.html" class="shop-link">Shop Now</a>
                                    </div>
                                    <img src="assets/images/custom/custom-2.png" alt="img"/>
                                </div>
                            </div>
                            <div class="col-xl-7 col-md-6">
                                <div class="custom-img-gallery h-calc wow fadeInUp" data-wow-delay="0.3s">
                                    <div class="gallery-content">
                                        <h4 class="title">Gray Chair in Room</h4>
                                        <p class="pera">Up to <span class="highlights">35%</span> off Item</p>
                                        <a href="shop.html" class="shop-link">Shop Now</a>
                                    </div>
                                    <img src="assets/images/custom/custom-4.png" alt="img"/>
                                </div>
                            </div>
                            <div class="col-xl-5 col-md-6">
                                <div class="custom-img-gallery h-calc wow fadeInUp" data-wow-delay="0.4s">
                                    <div class="gallery-content">
                                        <h4 class="title">Comfy Chairs Sink</h4>
                                        <p class="pera">Up to <span class="highlights">25%</span> off Item</p>
                                        <a href="shop.html" class="shop-link">Shop Now</a>
                                    </div>
                                    <img src="assets/images/custom/custom-5.png" alt="img"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-5">
                        <div class="custom-img-gallery res-img-gallery h-calc wow fadeInUp" data-wow-delay="0.0s">
                            <div class="gallery-content">
                                <h4 class="title">Lambert & Fils Laurent</h4>
                                <p class="pera">Up to <span class="highlights">50%</span> off Item</p>
                                <a href="shop.html" class="shop-link">Shop Now</a>
                            </div>
                            <img src="assets/images/custom/custom-3.png" alt="img"/>
                        </div>
                    </div>

                </div>
            </div>

        </section>
        {/* <!-- End-of custom--> */}

        {/* <!-- Feature area S t a r t --> */}
        <section class="feature-area feature-bg position-relative">
            <div class="container">
                <div class="row">
					<div class="col-lg-12">
                        <div class="section-title">
                            <h4 class="title">Featured Products</h4>
                        </div>
                        <div class="swiper featureSwiper-active">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide h-calc">
                                    <div class="product-card-two feature-card-two h-calc">
                                        <div class="top-card">
                                            <div class="discount-badge">
                                                <span class="percentage">- 25%</span>
                                            </div>
                                        </div>
                                        <div class="product-img-card-two feature-img-card-two">
                                            <a href="shop-details.html" class="zoomImg">
                                                <img src="assets/images/product/product-11.png" alt="img"/>
                                            </a>
                                            <div class="special-icon">
                                                <button class="icon-btn">
                                                    <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                                </button>
                                                <button class="icon-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                        viewBox="0 0 21 19" fill="none">
                                                        <path
                                                            d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                            fill="#111111" />
                                                    </svg>
                                                </button>
                                                <button class="icon-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                        viewBox="0 0 22 25" fill="none">
                                                        <path
                                                            d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                            fill="#111111" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="product-info-two">
                                            <div class="wrapper">
                                                <a href="shop-details.html" >
                                                    <h4 class="product-title line-clamp-1">Golden Steel Chair</h4>
                                                </a>
                                                <div class="product-review-two">
                                                    <div class="product-ratting">
                                                        <i class="ri-star-s-fill"></i>
                                                        <i class="ri-star-s-fill"></i>
                                                        <i class="ri-star-s-fill"></i>
                                                        <i class="ri-star-s-fill"></i>
                                                        <i class="ri-star-s-fill"></i>
                                                    </div>
                                                    <p class="count-ratting">(22)</p>
                                                </div>
                                                <div class="price-section">
                                                    <h4 class="price discounted">$180</h4>
                                                    <h4 class="price text-color-primary">$110</h4>
                                                </div>
                                                <div class="button-section1">
                                                    <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide h-calc">
                                    <div class="product-card-two feature-card-two h-calc">
                                        <div class="product-img-card-two feature-img-card-two">
                                            <a href="shop-details.html" class="zoomImg">
                                                <img src="assets/images/product/product-10.png" alt="img"/>
                                            </a>
                                            <div class="special-icon">
                                                <button class="icon-btn">
                                                    <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                                </button>
                                                <button class="icon-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                        viewBox="0 0 21 19" fill="none">
                                                        <path
                                                            d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                            fill="#111111" />
                                                    </svg>
                                                </button>
                                                <button class="icon-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                        viewBox="0 0 22 25" fill="none">
                                                        <path
                                                            d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                            fill="#111111" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        
                                        <div class="product-info-two">
                                            <a href="shop-details.html">
                                                <h4 class="product-title line-clamp-1">Rosegold Coffee Table</h4>
                                            </a>
                                            <div class="product-review-two">
                                                <div class="product-ratting">
                                                    <i class="ri-star-s-fill"></i>
                                                    <i class="ri-star-s-fill"></i>
                                                    <i class="ri-star-s-fill"></i>
                                                    <i class="ri-star-s-fill"></i>
                                                    <i class="ri-star-s-fill"></i>
                                                </div>
                                                <p class="count-ratting">(33)</p>
                                            </div>
                                            <div class="price-section">
                                                <h4 class="price text-color-primary">$500</h4>
                                            </div>
                                            <div class="button-section1">
                                                <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide h-calc">
                                    <div class="product-card-two feature-card-two h-calc">
                                        <div class="top-card">
                                            <div class="discount-badge">
                                                <span class="percentage">- 25%</span>
                                            </div>
                                        </div>
                                        <div class="product-img-card-two feature-img-card-two">
                                            <a href="shop-details.html" class="zoomImg">
                                                <img src="assets/images/product/product-13.png" alt="img"/>
                                            </a>
                                            <div class="special-icon">
                                                <button class="icon-btn">
                                                    <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                                </button>
                                                <button class="icon-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                        viewBox="0 0 21 19" fill="none">
                                                        <path
                                                            d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                            fill="#111111" />
                                                    </svg>
                                                </button>
                                                <button class="icon-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                        viewBox="0 0 22 25" fill="none">
                                                        <path
                                                            d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                            fill="#111111" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="product-info-two">
                                            <a href="shop-details.html">
                                                <h4 class="product-title line-clamp-1">Luxury Hayatul Chair</h4>
                                            </a>
                                            <div class="product-review-two">
                                                <div class="product-ratting">
                                                    <i class="ri-star-s-fill"></i>
                                                    <i class="ri-star-s-fill"></i>
                                                    <i class="ri-star-s-fill"></i>
                                                    <i class="ri-star-s-fill"></i>
                                                    <i class="ri-star-s-fill"></i>
                                                </div>
                                                <p class="count-ratting">(22)</p>
                                            </div>
                                            <div class="price-section">
                                                <h4 class="price discounted">$350</h4>
                                                <h4 class="price text-color-primary">$320</h4>
                                            </div>
                                            <div class="button-section1">
                                                <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide h-calc">
                                    <div class="product-card-two feature-card-two h-calc">
                                        <div class="product-img-card-two feature-img-card-two">
                                            <a href="shop-details.html" class="zoomImg">
                                                <img src="assets/images/product/product-14.png" alt="img"/>
                                            </a>
                                            <div class="special-icon">
                                                <button class="icon-btn">
                                                    <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                                </button>
                                                <button class="icon-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                        viewBox="0 0 21 19" fill="none">
                                                        <path
                                                            d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                            fill="#111111" />
                                                    </svg>
                                                </button>
                                                <button class="icon-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                        viewBox="0 0 22 25" fill="none">
                                                        <path
                                                            d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                            fill="#111111" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="product-info-two">
                                            <a href="shop-details.html">
                                                <h4 class="product-title line-clamp-1">Glod Collision Light</h4>
                                            </a>
                                            <div class="product-review-two">
                                                <div class="product-ratting">
                                                    <i class="ri-star-s-fill"></i>
                                                    <i class="ri-star-s-fill"></i>
                                                    <i class="ri-star-s-fill"></i>
                                                    <i class="ri-star-s-fill"></i>
                                                    <i class="ri-star-s-fill"></i>
                                                </div>
                                                <p class="count-ratting">(11)</p>
                                            </div>
                                            <div class="price-section">
                                                <h4 class="price text-color-primary">$500</h4>
                                            </div>
                                            <div class="button-section1">
                                                <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide h-calc">
                                    <div class="product-card-two feature-card-two h-calc">
                                        <div class="top-card">
                                            <div class="discount-badge">
                                                <span class="percentage">- 25%</span>
                                            </div>
                                        </div>
                                        <div class="product-img-card-two feature-img-card-two">
                                            <a href="shop-details.html" class="zoomImg">
                                                <img src="assets/images/product/product-11.png" alt="img"/>
                                            </a>
                                            <div class="special-icon">
                                                <button class="icon-btn">
                                                    <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                                </button>
                                                <button class="icon-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                        viewBox="0 0 21 19" fill="none">
                                                        <path
                                                            d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                            fill="#111111" />
                                                    </svg>
                                                </button>
                                                <button class="icon-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                        viewBox="0 0 22 25" fill="none">
                                                        <path
                                                            d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                            fill="#111111" />
                                                        <path
                                                            d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                            fill="#111111" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="product-info-two">
                                            <a href="shop-details.html">
                                                <h4 class="product-title line-clamp-1">Golden Steel Chair</h4>
                                            </a>
                                            <div class="product-review-two">
                                                <div class="product-ratting">
                                                    <i class="ri-star-s-fill"></i>
                                                    <i class="ri-star-s-fill"></i>
                                                    <i class="ri-star-s-fill"></i>
                                                    <i class="ri-star-s-fill"></i>
                                                    <i class="ri-star-s-fill"></i>
                                                </div>
                                                <p class="count-ratting">(22)</p>
                                            </div>
                                            <div class="price-section">
                                                <h4 class="price discounted">$180</h4>
                                                <h4 class="price text-color-primary">$110</h4>
                                            </div>
                                            <div class="button-section1">
                                                <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-button-next swiper-common-btn"><i class="ri-arrow-right-s-line"></i></div>
                            <div class="swiper-button-prev swiper-common-btn"><i class="ri-arrow-left-s-line"></i></div>
                        </div>
					</div>
				</div>
            </div>
        </section>
        {/* <!-- End-of feature--> */}

        {/* <!-- Categories area S t a r t --> */}
        <section class="category-area section-padding2">
            <div class="container">
                <div class="row">
					<div class="col-lg-12">
                        <div class="section-with-button">
                            <div class="section-title position-relative">
                                <h4 class="title">Popular Categories</h4>
                            </div>
                            <div class="text-center d-block">
                                <a href="shop.html" class="outline-pill-btn ">
                                    See All Categories
                                    <svg xmlns="http://www.w3.org/2000/svg" width="78" height="19" viewBox="0 0 78 19"
                                        fill="none">
                                        <path
                                            d="M66.918 10.9147C66.8987 10.9909 66.8754 11.0659 66.8482 11.1394C66.3343 12.5191 65.8568 13.9149 65.3832 15.3094C65.2835 15.6007 65.0431 15.8908 65.3278 16.3278C68.9295 13.4161 73.0932 11.4878 77.3487 9.65131C72.9717 7.73141 68.8104 5.59576 65.0804 2.61703C64.8556 3.06744 65.0978 3.36045 65.2072 3.6577C65.7259 5.06223 66.2433 6.47061 66.7965 7.85894C67.1854 8.84516 67.2283 9.92384 66.918 10.9147Z"
                                            fill="currentColor" />
                                        <rect y="8.90649" width="68" height="1" rx="0.5" fill="currentColor" />
                                    </svg>
                                </a>
                            </div>
                        </div>						
					</div>
				</div>
                <div class="row g-4">
                    <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="category-card-two h-calc wow fadeInUp" data-wow-delay="0.0s">
                            <a href="shop.html" class="zoomImg">
                                <img src="assets/images/category/category-7.png" alt="img"/>
                                <div class="categories-content-two">
                                    <h4 class="title">Chandeliers</h4>
                                    <p class="pera">15 Products</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="category-card-two h-calc wow fadeInUp" data-wow-delay="0.0s">
                            <a href="shop.html" class="zoomImg">
                                <img src="assets/images/category/category-8.png" alt="img"/>
                                <div class="categories-content-two">
                                    <h4 class="title">Wooden Chairs</h4>
                                    <p class="pera">111 Products</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="category-card-two h-calc wow fadeInUp" data-wow-delay="0.1s">
                            <a href="shop.html" class="zoomImg">
                                <img src="assets/images/category/category-9.png" alt="img"/>
                                <div class="categories-content-two">
                                    <h4 class="title">Fabric Sofas</h4>
                                    <p class="pera">34 Products</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="category-card-two h-calc wow fadeInUp" data-wow-delay="0.1s">
                            <a href="shop.html" class="zoomImg">
                                <img src="assets/images/category/category-10.png" alt="img"/>
                                <div class="categories-content-two">
                                    <h4 class="title">Bookshelves</h4>
                                    <p class="pera">27 Products</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="category-card-two h-calc wow fadeInUp" data-wow-delay="0.2s">
                            <a href="shop.html" class="zoomImg">
                                <img src="assets/images/category/category-11.png" alt="img"/>
                                <div class="categories-content-two">
                                    <h4 class="title">Sit Table</h4>
                                    <p class="pera">39 Products</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="category-card-two h-calc wow fadeInUp" data-wow-delay="0.2s">
                            <a href="shop.html" class="zoomImg">
                                <img src="assets/images/category/category-12.png" alt="img"/>
                                <div class="categories-content-two">
                                    <h4 class="title">Luxury Bed</h4>
                                    <p class="pera">85 Products</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="category-card-two h-calc wow fadeInUp" data-wow-delay="0.3s">
                            <a href="shop.html" class="zoomImg">
                                <img src="assets/images/category/category-13.png" alt="img"/>
                                <div class="categories-content-two">
                                    <h4 class="title">Plu Side Table</h4>
                                    <p class="pera">120 Products</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="category-card-two h-calc wow fadeInUp" data-wow-delay="0.3s">
                            <a href="shop.html" class="zoomImg">
                                <img src="assets/images/category/category-14.png" alt="img"/>
                                <div class="categories-content-two">
                                    <h4 class="title">Armchairs</h4>
                                    <p class="pera">44 Products</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="category-card-two h-calc wow fadeInUp" data-wow-delay="0.4s">
                            <a href="shop.html" class="zoomImg">
                                <img src="assets/images/category/category-15.png" alt="img"/>
                                <div class="categories-content-two">
                                    <h4 class="title">Shoe Cabinets</h4>
                                    <p class="pera">55 Products</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="category-card-two h-calc wow fadeInUp" data-wow-delay="0.4s">
                            <a href="shop.html" class="zoomImg">
                                <img src="assets/images/category/category-16.png" alt="img"/>
                                <div class="categories-content-two">
                                    <h4 class="title">Luxury Lamp</h4>
                                    <p class="pera">102 Products</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="category-card-two h-calc wow fadeInUp" data-wow-delay="0.5s">
                            <a href="shop.html" class="zoomImg">
                                <img src="assets/images/category/category-17.png" alt="img"/>
                                <div class="categories-content-two">
                                    <h4 class="title">Office Chairs</h4>
                                    <p class="pera">99 Products</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="category-card-two h-calc wow fadeInUp" data-wow-delay="0.5s">
                            <a href="shop.html" class="zoomImg">
                                <img src="assets/images/category/category-18.png" alt="img"/>
                                <div class="categories-content-two">
                                    <h4 class="title">Fabric Sofa</h4>
                                    <p class="pera">10 Products</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </section>
        {/* <!-- End-of categories--> */}

        {/* <!-- Deals area S t a r t --> */}
        <section class="deals-area-two section-padding2">
            <div class="container">
                <div class="section-title p-0">
                    <h4 class="title">Deal of the Week Let's <span class="outline-text">Shopping</span> Today</h4>
                </div>
                <div class="row g-4">
                    <div class="col-xl-12">
                        <div class="swiper dealSwiper-active">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <div class="deals-card-two">
                                        <div class="row align-items-center g-4">
                                            <div class="col-xl-6">
                                                <div class="deals-banner-two">
                                                    <img src="assets/images/gallery/deal-banner-2.png" alt="img"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-6">
                                                <div class="deals-content-two">
                                                    <p class="deal-subtitle">Caracole Go For A Spin Chair</p>

                                                    <div class="bottom-content-two">
                                                        <div class="price-section">
                                                            <h4 class="price discounted">$450</h4>
                                                            <h4 class="price text-color-primary">$350</h4>
                                                        </div>
                                                        <a href="javascript:void(0)" class="pill-btn-primary-sm">Shop
                                                            Now</a>
                                                    </div>
                                                    <div class="progress-section-two">
                                                        <div class="progress-content-two">
                                                            <h4 class="title">Availavle: 15</h4>
                                                            <p class="title">Already Sold: 85</p>
                                                        </div>
                                                        <div class="progress custom-progress-three">
                                                            <div class="progress-bar" style={{width:"40%"}}></div>
                                                        </div>

                                                    </div>
                                                    <div class="deals-footer-two">
                                                        <div class="section-title p-0">
                                                            <h4 class="title">Hurry Up:</h4>
                                                            <p class="pera">Offer ends in</p>
                                                        </div>
                                                        <div class="count-down-two">
                                                            <div class="circle">
                                                                <h4 class="number">20</h4>
                                                                <p class="pera">Days</p>
                                                            </div>
                                                            <div class="circle">
                                                                <h4 class="number">12</h4>
                                                                <p class="pera">Hours</p>
                                                            </div>
                                                            <div class="circle">
                                                                <h4 class="number">42</h4>
                                                                <p class="pera">Min</p>
                                                            </div>
                                                            <div class="circle">
                                                                <h4 class="number">23</h4>
                                                                <p class="pera">Sec</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="deals-card-two">
                                        <div class="row align-items-center g-4">
                                            <div class="col-xl-6">
                                                <div class="deals-banner-two">
                                                    <img src="assets/images/gallery/deal-banner-2a.png" alt="img"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-6">
                                                <div class="deals-content-two">
                                                    <p class="deal-subtitle">Caracole Go For A Luxury Furniture</p>

                                                    <div class="bottom-content-two">
                                                        <div class="price-section">
                                                            <h4 class="price discounted">$550</h4>
                                                            <h4 class="price text-color-primary">$450</h4>
                                                        </div>
                                                        <a href="javascript:void(0)" class="pill-btn-primary-sm">Shop
                                                            Now</a>
                                                    </div>
                                                    <div class="progress-section-two">
                                                        <div class="progress-content-two">
                                                            <h4 class="title">Availavle: 45</h4>
                                                            <p class="title">Already Sold: 95</p>
                                                        </div>
                                                        <div class="progress custom-progress-three">
                                                            <div class="progress-bar" style={{width: "40%"}}></div>
                                                        </div>

                                                    </div>
                                                    <div class="deals-footer-two">
                                                        <div class="section-title p-0">
                                                            <h4 class="title">Hurry Up:</h4>
                                                            <p class="pera">Offer ends in</p>
                                                        </div>
                                                        <div class="count-down-two">
                                                            <div class="circle">
                                                                <h4 class="number">20</h4>
                                                                <p class="pera">Days</p>
                                                            </div>
                                                            <div class="circle">
                                                                <h4 class="number">12</h4>
                                                                <p class="pera">Hours</p>
                                                            </div>
                                                            <div class="circle">
                                                                <h4 class="number">42</h4>
                                                                <p class="pera">Min</p>
                                                            </div>
                                                            <div class="circle">
                                                                <h4 class="number">23</h4>
                                                                <p class="pera">Sec</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-button-next swiper-common-btn"><i class="ri-arrow-right-s-line"></i>
                            </div>
                            <div class="swiper-button-prev swiper-common-btn"><i class="ri-arrow-left-s-line"></i></div>
                        </div>
                    </div>
                </div>
            </div>
    
        </section>
        {/* <!-- End-of feature--> */}

        {/* <!-- Best product area S t a r t --> */}
        <section class="best-product-area-two">
            <div class="container">
                <div class="row g-4">
                    <div class="col-xxl-12">
                        <div class="section-with-button">
                            <div class="section-title title position-relative mb-40">
                                <h4 class="title">Our Best All Products <span class="outline-text">Collections</span>
                                </h4>
                                <div class="shape bounce-animate">
                                    <img src="assets/images/icon/title-shape.png" alt="img"/>
                                </div>
                            </div>
                            <ul class="nav nav-pills product-pills mb-3" id="pills-tab" role="tablist">
                                <li class="nav-item product-item" role="presentation">
                                    <button class="nav-link product-link active" id="pills-all-tab"
                                        data-bs-toggle="pill" data-bs-target="#pills-all" type="button" role="tab"
                                        aria-controls="pills-all" aria-selected="true">All</button>
                                </li>
                                <li class="nav-item product-item" role="presentation">
                                    <button class="nav-link product-link" id="pills-sofa-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-sofa" type="button" role="tab" aria-controls="pills-sofa"
                                        aria-selected="false">Sofa</button>
                                </li>
                                <li class="nav-item product-item" role="presentation">
                                    <button class="nav-link product-link" id="pills-chair-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-chair" type="button" role="tab"
                                        aria-controls="pills-chair" aria-selected="false">Chair</button>
                                </li>
                                <li class="nav-item product-item" role="presentation">
                                    <button class="nav-link product-link" id="pills-furniture-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-furniture" type="button" role="tab"
                                        aria-controls="pills-furniture" aria-selected="false">Furnitures</button>
                                </li>
                                <li class="nav-item product-item" role="presentation">
                                    <button class="nav-link product-link" id="pills-table-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-table" type="button" role="tab"
                                        aria-controls="pills-table" aria-selected="false">Table</button>
                                </li>
                                <li class="nav-item product-item" role="presentation">
                                    <button class="nav-link product-link" id="pills-wall-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-wall" type="button" role="tab" aria-controls="pills-wall"
                                        aria-selected="false">Wall Light</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-all" role="tabpanel"
                        aria-labelledby="pills-all-tab">
                        <div class="row g-4">
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 25%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                        <a href="shop-details.html" class="bounce-animate">
                                            <img src="assets/images/product/product-15.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Fluted Luxury Side Table</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(55)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$180</h4>
                                            <h4 class="price text-color-primary">$110</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-16.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Spanish Organic wood</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(12)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$590</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 30%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-17.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Mid-Century Modern Cane</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(23)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$740</h4>
                                            <h4 class="price text-color-primary">$680</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-18.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Orange Sectional Sofa</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(12)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$590</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-19.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Rods Base Table Lamp</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(08)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$980</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-20.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">King Upholstered Velvet</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(57)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$850</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 28%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-21.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Head Bedroom Wall Light</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(22)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$200</h4>
                                            <h4 class="price text-color-primary">$150</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 25%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-22.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Golden Steel Chair</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(22)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$200</h4>
                                            <h4 class="price text-color-primary">$150</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-sofa" role="tabpanel" aria-labelledby="pills-sofa-tab">
                        <div class="row g-4">
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 25%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-1.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Fluted Luxury Side Table</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(55)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$180</h4>
                                            <h4 class="price text-color-primary">$110</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-2.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Spanish Organic wood</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(12)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$590</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 30%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-3.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Mid-Century Modern Cane</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(23)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$740</h4>
                                            <h4 class="price text-color-primary">$680</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-4.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Orange Sectional Sofa</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(12)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$590</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-5.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Rods Base Table Lamp</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(08)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$980</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-6.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">King Upholstered Velvet</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(57)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$850</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 28%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-7.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Head Bedroom Wall Light</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(22)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$200</h4>
                                            <h4 class="price text-color-primary">$150</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 25%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-8.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                      <a href="shop-details.html" class="zoomImg">
                                            <h4 class="product-title line-clamp-1">Golden Steel Chair</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(22)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$200</h4>
                                            <h4 class="price text-color-primary">$150</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-chair" role="tabpanel" aria-labelledby="pills-chair-tab">
                        <div class="row g-4">
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 25%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-9.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Fluted Luxury Side Table</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(55)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$180</h4>
                                            <h4 class="price text-color-primary">$110</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-10.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Spanish Organic wood</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(12)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$590</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 30%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-11.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Mid-Century Modern Cane</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(23)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$740</h4>
                                            <h4 class="price text-color-primary">$680</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-10.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Orange Sectional Sofa</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(12)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$590</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-13.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Rods Base Table Lamp</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(08)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$980</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-14.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">King Upholstered Velvet</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(57)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$850</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 28%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-15.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                      <a href="shop-details.html" class="zoomImg">
                                            <h4 class="product-title line-clamp-1">Head Bedroom Wall Light</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(22)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$200</h4>
                                            <h4 class="price text-color-primary">$150</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 25%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-16.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Golden Steel Chair</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(22)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$200</h4>
                                            <h4 class="price text-color-primary">$150</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-furniture" role="tabpanel"
                        aria-labelledby="pills-furniture-tab">
                        <div class="row g-4">
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 25%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-1.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Fluted Luxury Side Table</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(55)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$180</h4>
                                            <h4 class="price text-color-primary">$110</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-2.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Spanish Organic wood</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(12)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$590</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 30%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-3.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Mid-Century Modern Cane</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(23)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$740</h4>
                                            <h4 class="price text-color-primary">$680</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-4.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Orange Sectional Sofa</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(12)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$590</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-5.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Rods Base Table Lamp</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(08)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$980</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-6.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">King Upholstered Velvet</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(57)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$850</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 28%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-7.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Head Bedroom Wall Light</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(22)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$200</h4>
                                            <h4 class="price text-color-primary">$150</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 25%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-8.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Golden Steel Chair</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(22)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$200</h4>
                                            <h4 class="price text-color-primary">$150</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-table" role="tabpanel" aria-labelledby="pills-table-tab">
                        <div class="row g-4">
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 25%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-9.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Fluted Luxury Side Table</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(55)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$180</h4>
                                            <h4 class="price text-color-primary">$110</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-10.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Spanish Organic wood</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(12)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$590</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 30%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-11.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Mid-Century Modern Cane</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(23)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$740</h4>
                                            <h4 class="price text-color-primary">$680</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-10.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Orange Sectional Sofa</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(12)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$590</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-13.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                      <a href="shop-details.html" class="zoomImg">
                                            <h4 class="product-title line-clamp-1">Rods Base Table Lamp</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(08)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$980</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-14.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">King Upholstered Velvet</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(57)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$850</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 28%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-15.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Head Bedroom Wall Light</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(22)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$200</h4>
                                            <h4 class="price text-color-primary">$150</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 25%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-16.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Golden Steel Chair</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(22)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$200</h4>
                                            <h4 class="price text-color-primary">$150</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-wall" role="tabpanel" aria-labelledby="pills-wall-tab">
                        <div class="row g-4">
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 25%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-1.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Fluted Luxury Side Table</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(55)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$180</h4>
                                            <h4 class="price text-color-primary">$110</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-2.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Spanish Organic wood</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(12)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$590</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 30%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-3.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Mid-Century Modern Cane</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(23)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$740</h4>
                                            <h4 class="price text-color-primary">$680</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-4.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Orange Sectional Sofa</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(12)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$590</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-5.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                      <a href="shop-details.html" class="zoomImg">
                                            <h4 class="product-title line-clamp-1">Rods Base Table Lamp</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(08)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$980</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-6.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">King Upholstered Velvet</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(57)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price text-color-primary">$850</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 28%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-7.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Head Bedroom Wall Light</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(22)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$200</h4>
                                            <h4 class="price text-color-primary">$150</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-3 col-xl-4 col-sm-6">
                                <div class="product-card-two best-product-card-two h-calc">
                                    <div class="top-card">
                                        <div class="discount-badge">
                                            <span class="percentage">- 25%</span>
                                        </div>
                                    </div>
                                    <div class="product-img-card-two feature-img-card-two ">
                                      <a href="shop-details.html" class="zoomImg">
                                            <img src="assets/images/product/product-8.png" alt="img"/>
                                        </a>
                                        <div class="special-icon">
                                            <button class="icon-btn">
                                                <img src="assets/images/icon/wish-icon-2.png" alt="img"/>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19"
                                                    viewBox="0 0 21 19" fill="none">
                                                    <path
                                                        d="M0.978546 2.56816V5.13649H1.65442H2.33029V3.71701V2.292L5.45619 5.35743L8.58209 8.42285L9.07209 7.9368L9.56773 7.45628L6.44183 4.39085L3.31593 1.32542H4.76905H6.21654V0.662629V-0.000165939H3.59754H0.978546V2.56816Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M15.1167 0.662629V1.32542H16.5642H18.0173L14.8914 4.39085L11.7655 7.45628L12.2611 7.9368L12.7511 8.42285L15.877 5.35743L19.0029 2.292V3.71701V5.13649H19.6788H20.3547V2.56816V-0.000165939H17.7357H15.1167V0.662629Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M5.43926 13.6535L2.33026 16.7078V15.2828V13.8633H1.65439H0.978516V16.4317V19H3.59751H6.21651V18.3372V17.6744H4.76902H3.3159L6.4418 14.609L9.5677 11.5436L9.08896 11.0741C8.82988 10.8145 8.59895 10.6046 8.58206 10.6046C8.56516 10.6046 7.15146 11.9799 5.43926 13.6535Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M12.2442 11.0741L11.7655 11.5436L14.8914 14.609L18.0173 17.6744H16.5641H15.1167V18.3372V19H17.7356H20.3546V16.4317V13.8633H19.6788H19.0029V15.2828V16.7078L15.8883 13.6535C14.1817 11.9799 12.768 10.6046 12.7511 10.6046C12.7342 10.6046 12.5033 10.8145 12.2442 11.0741Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                            <button class="icon-btn">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"
                                                    viewBox="0 0 22 25" fill="none">
                                                    <path
                                                        d="M9.4304 0.827005C9.4304 1.27952 9.41567 1.65419 9.40094 1.65419C9.38622 1.65419 9.14565 1.69798 8.87563 1.75637C4.21162 2.6906 0.73079 6.34481 0.0827378 10.9868C-0.0694564 12.0475 -0.00563302 13.663 0.225113 14.7432C0.809342 17.5069 2.62094 20.1004 5.01677 21.6185L5.29661 21.7937L5.98885 21.2779C6.36688 20.9957 6.68109 20.7524 6.68109 20.733C6.68109 20.7184 6.55835 20.6405 6.41107 20.5627C5.65992 20.1734 4.68293 19.3949 4.02015 18.6601C2.76332 17.2636 1.95816 15.5606 1.69796 13.77C1.60468 13.1083 1.60468 11.7945 1.69796 11.1668C1.89925 9.83845 2.37056 8.58308 3.07753 7.48341C3.96123 6.11612 5.30643 4.91914 6.77928 4.1844C7.52552 3.8146 8.56142 3.46426 9.2733 3.33775L9.4304 3.30856V3.98977C9.4304 4.36444 9.44022 4.67098 9.45495 4.67098C9.50404 4.67098 12.5725 2.36946 12.5725 2.3354C12.5725 2.30134 9.50404 -0.000179291 9.45495 -0.000179291C9.44022 -0.000179291 9.4304 0.369621 9.4304 0.827005Z"
                                                        fill="#111111" />
                                                    <path
                                                        d="M16.0134 3.6345C15.6354 3.91672 15.331 4.16487 15.3457 4.18434C15.3604 4.20867 15.5077 4.30112 15.6795 4.39357C17.118 5.20129 18.4583 6.56371 19.288 8.07697C19.7888 8.98687 20.211 10.3104 20.3288 11.3419C20.427 12.1545 20.3681 13.551 20.211 14.2906C19.5335 17.4728 17.2997 20.0371 14.2165 21.1757C13.8287 21.3168 12.778 21.6039 12.6406 21.6039C12.5866 21.6039 12.5718 21.4822 12.5718 20.9227C12.5718 20.548 12.562 20.2414 12.5473 20.2414C12.4982 20.2414 9.42976 22.543 9.42976 22.577C9.42976 22.6111 12.4982 24.9126 12.5473 24.9126C12.562 24.9126 12.5718 24.5428 12.5718 24.0854V23.2631L12.7584 23.2339C13.215 23.1609 14.5062 22.8154 14.9284 22.65C18.7578 21.1659 21.3795 17.8767 21.9244 13.8673C22.0668 12.8357 22.003 11.2203 21.7771 10.1693C21.1929 7.40549 19.3813 4.81202 16.9855 3.2939L16.7056 3.11873L16.0134 3.6345Z"
                                                        fill="#111111" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="product-info-two best-product-info-two">
                                        <a href="shop-details.html">
                                            <h4 class="product-title line-clamp-1">Golden Steel Chair</h4>
                                        </a>
                                        <div class="product-review-two">
                                            <div class="product-ratting">
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                                <i class="ri-star-s-fill"></i>
                                            </div>
                                            <p class="count-ratting">(22)</p>
                                        </div>
                                        <div class="price-section">
                                            <h4 class="price discounted">$200</h4>
                                            <h4 class="price text-color-primary">$150</h4>
                                        </div>
                                        <div class="button-section1">
                                            <a href="javascript:void(0)" class=" cart-btn-two">Add to Cart</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End-of best product--> */}

		{/* <!-- News area S t a r t --> */}
		<section class="news-area bottom-padding1">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="section-title">
							<h4 class="title">Latest Blog & News</h4>
						</div>
						<div class="swiper newsSwiper-active">
							<div class="swiper-wrapper">
								<div class="swiper-slide">
									<div class="news-card">
										<a href="blog-details.html">
											<div class="news-img zoomImg">
												<img src="assets/images/news/news-1.png" alt="img"/>
												<div class="news-badge">
													<span class="date">
														04 April 2024
													</span>
												</div>
											</div>
										</a>
										<div class="author-info">
											<div class="icon-with-title">
												<i class="ri-user-line"></i>
												<p class="pera">By Admin</p>
											</div>
											<div class="icon-with-title">
												<i class="ri-message-2-line"></i>
												<p class="pera">12 Comments</p>
											</div>
										</div>
										<div class="news-content">
											<a href="blog-details.html">
												<h4 class="news-title line-clamp-2">Modern studio apartment design bedroom and
													living space</h4>
											</a>
										</div>
										<a href="blog-details.html" class="read-more-text">Read More <svg
												xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10"
												fill="none">
												<path
													d="M12.6227 4.38164C12.5587 4.38164 12.4989 4.38164 12.4349 4.38164C8.56302 4.38164 4.69114 4.38164 0.819254 4.38164C0.7168 4.38164 0.614347 4.37773 0.516163 4.40117C0.195996 4.46758 -0.0302552 4.76447 0.00389589 5.05746C0.0423159 5.37779 0.302718 5.60827 0.644229 5.6278C0.712532 5.63171 0.780834 5.63171 0.853405 5.63171C4.71248 5.63171 8.57583 5.63171 12.4349 5.63171C12.4989 5.63171 12.5587 5.63171 12.6654 5.63171C12.5971 5.69812 12.5587 5.73719 12.516 5.77625C11.3805 6.81928 10.2407 7.86231 9.10517 8.90534C8.82342 9.16317 8.79354 9.51866 9.0326 9.77648C9.27166 10.0382 9.68574 10.0773 9.98029 9.86633C10.0272 9.83508 10.0657 9.79602 10.1084 9.75695C11.6494 8.34671 13.1905 6.93257 14.7273 5.51842C15.0987 5.17856 15.0987 4.8387 14.7273 4.49883C13.1777 3.07687 11.6238 1.65491 10.0742 0.229051C9.8693 0.0415392 9.63878 -0.0483093 9.35276 0.0259132C8.88319 0.147015 8.70389 0.670483 9.00698 1.01425C9.0454 1.06113 9.09236 1.10019 9.13932 1.14317C10.2663 2.17448 11.389 3.20969 12.5203 4.241C12.563 4.28007 12.6185 4.2996 12.6654 4.33085C12.6483 4.34257 12.6355 4.3621 12.6227 4.38164Z"
													fill="#696969" />
											</svg>
										</a>
									</div>
								</div>
								<div class="swiper-slide">
									<div class="news-card">
										<a href="blog-details.html">
											<div class="news-img zoomImg">
												<img src="assets/images/news/news-2.png" alt="img"/>
												<div class="news-badge">
													<span class="date">
														04 April 2024
													</span>
												</div>
											</div>
										</a>
										<div class="author-info">
											<div class="icon-with-title">
												<i class="ri-user-line"></i>
												<p class="pera">By Admin</p>
											</div>
											<div class="icon-with-title">
												<i class="ri-message-2-line"></i>
												<p class="pera">12 Comments</p>
											</div>
										</div>
										<div class="news-content">
											<a href="blog-details.html">
												<h4 class="news-title line-clamp-2">Comfortable armchair in a room decorated
												</h4>
											</a>
										</div>
										<a href="blog-details.html" class="read-more-text">Read More <svg
												xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10"
												fill="none">
												<path
													d="M12.6227 4.38164C12.5587 4.38164 12.4989 4.38164 12.4349 4.38164C8.56302 4.38164 4.69114 4.38164 0.819254 4.38164C0.7168 4.38164 0.614347 4.37773 0.516163 4.40117C0.195996 4.46758 -0.0302552 4.76447 0.00389589 5.05746C0.0423159 5.37779 0.302718 5.60827 0.644229 5.6278C0.712532 5.63171 0.780834 5.63171 0.853405 5.63171C4.71248 5.63171 8.57583 5.63171 12.4349 5.63171C12.4989 5.63171 12.5587 5.63171 12.6654 5.63171C12.5971 5.69812 12.5587 5.73719 12.516 5.77625C11.3805 6.81928 10.2407 7.86231 9.10517 8.90534C8.82342 9.16317 8.79354 9.51866 9.0326 9.77648C9.27166 10.0382 9.68574 10.0773 9.98029 9.86633C10.0272 9.83508 10.0657 9.79602 10.1084 9.75695C11.6494 8.34671 13.1905 6.93257 14.7273 5.51842C15.0987 5.17856 15.0987 4.8387 14.7273 4.49883C13.1777 3.07687 11.6238 1.65491 10.0742 0.229051C9.8693 0.0415392 9.63878 -0.0483093 9.35276 0.0259132C8.88319 0.147015 8.70389 0.670483 9.00698 1.01425C9.0454 1.06113 9.09236 1.10019 9.13932 1.14317C10.2663 2.17448 11.389 3.20969 12.5203 4.241C12.563 4.28007 12.6185 4.2996 12.6654 4.33085C12.6483 4.34257 12.6355 4.3621 12.6227 4.38164Z"
													fill="#696969" />
											</svg>
										</a>
									</div>
								</div>
								<div class="swiper-slide">
									<div class="news-card">
										<a href="blog-details.html">
											<div class="news-img zoomImg">
												<img src="assets/images/news/news-3.png" alt="img"/>
												<div class="news-badge">
													<span class="date">
														04 April 2024
													</span>
												</div>
											</div>
										</a>
										<div class="author-info">
											<div class="icon-with-title">
												<i class="ri-user-line"></i>
												<p class="pera">By Admin</p>
											</div>
											<div class="icon-with-title">
												<i class="ri-message-2-line"></i>
												<p class="pera">12 Comments</p>
											</div>
										</div>
										<div class="news-content">
											<a href="blog-details.html">
												<h4 class="news-title line-clamp-2">Cafe with coffee tables as sofas plants and
													shelves</h4>
											</a>
										</div>
										<a href="blog-details.html" class="read-more-text">Read More <svg
												xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10"
												fill="none">
												<path
													d="M12.6227 4.38164C12.5587 4.38164 12.4989 4.38164 12.4349 4.38164C8.56302 4.38164 4.69114 4.38164 0.819254 4.38164C0.7168 4.38164 0.614347 4.37773 0.516163 4.40117C0.195996 4.46758 -0.0302552 4.76447 0.00389589 5.05746C0.0423159 5.37779 0.302718 5.60827 0.644229 5.6278C0.712532 5.63171 0.780834 5.63171 0.853405 5.63171C4.71248 5.63171 8.57583 5.63171 12.4349 5.63171C12.4989 5.63171 12.5587 5.63171 12.6654 5.63171C12.5971 5.69812 12.5587 5.73719 12.516 5.77625C11.3805 6.81928 10.2407 7.86231 9.10517 8.90534C8.82342 9.16317 8.79354 9.51866 9.0326 9.77648C9.27166 10.0382 9.68574 10.0773 9.98029 9.86633C10.0272 9.83508 10.0657 9.79602 10.1084 9.75695C11.6494 8.34671 13.1905 6.93257 14.7273 5.51842C15.0987 5.17856 15.0987 4.8387 14.7273 4.49883C13.1777 3.07687 11.6238 1.65491 10.0742 0.229051C9.8693 0.0415392 9.63878 -0.0483093 9.35276 0.0259132C8.88319 0.147015 8.70389 0.670483 9.00698 1.01425C9.0454 1.06113 9.09236 1.10019 9.13932 1.14317C10.2663 2.17448 11.389 3.20969 12.5203 4.241C12.563 4.28007 12.6185 4.2996 12.6654 4.33085C12.6483 4.34257 12.6355 4.3621 12.6227 4.38164Z"
													fill="#696969" />
											</svg>
										</a>
									</div>
								</div>
								<div class="swiper-slide">
									<div class="news-card">
										<a href="blog-details.html">
											<div class="news-img zoomImg">
												<img src="assets/images/news/news-1.png" alt="img"/>
												<div class="news-badge">
													<span class="date">
														04 April 2024
													</span>
												</div>
											</div>
										</a>
										<div class="author-info">
											<div class="icon-with-title">
												<i class="ri-user-line"></i>
												<p class="pera">By Admin</p>
											</div>
											<div class="icon-with-title">
												<i class="ri-message-2-line"></i>
												<p class="pera">12 Comments</p>
											</div>
										</div>
										<div class="news-content">
											<a href="blog-details.html">
												<h4 class="news-title line-clamp-2">Modern studio apartment design bedroom and
													living space</h4>
											</a>
										</div>
										<a href="blog-details.html" class="read-more-text">Read More <svg
												xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10"
												fill="none">
												<path
													d="M12.6227 4.38164C12.5587 4.38164 12.4989 4.38164 12.4349 4.38164C8.56302 4.38164 4.69114 4.38164 0.819254 4.38164C0.7168 4.38164 0.614347 4.37773 0.516163 4.40117C0.195996 4.46758 -0.0302552 4.76447 0.00389589 5.05746C0.0423159 5.37779 0.302718 5.60827 0.644229 5.6278C0.712532 5.63171 0.780834 5.63171 0.853405 5.63171C4.71248 5.63171 8.57583 5.63171 12.4349 5.63171C12.4989 5.63171 12.5587 5.63171 12.6654 5.63171C12.5971 5.69812 12.5587 5.73719 12.516 5.77625C11.3805 6.81928 10.2407 7.86231 9.10517 8.90534C8.82342 9.16317 8.79354 9.51866 9.0326 9.77648C9.27166 10.0382 9.68574 10.0773 9.98029 9.86633C10.0272 9.83508 10.0657 9.79602 10.1084 9.75695C11.6494 8.34671 13.1905 6.93257 14.7273 5.51842C15.0987 5.17856 15.0987 4.8387 14.7273 4.49883C13.1777 3.07687 11.6238 1.65491 10.0742 0.229051C9.8693 0.0415392 9.63878 -0.0483093 9.35276 0.0259132C8.88319 0.147015 8.70389 0.670483 9.00698 1.01425C9.0454 1.06113 9.09236 1.10019 9.13932 1.14317C10.2663 2.17448 11.389 3.20969 12.5203 4.241C12.563 4.28007 12.6185 4.2996 12.6654 4.33085C12.6483 4.34257 12.6355 4.3621 12.6227 4.38164Z"
													fill="#696969" />
											</svg>
										</a>
									</div>
								</div>
								<div class="swiper-slide">
									<div class="news-card">
										<a href="blog-details.html">
											<div class="news-img zoomImg">
												<img src="assets/images/news/news-2.png" alt="img"/>
												<div class="news-badge">
													<span class="date">
														04 April 2024
													</span>
												</div>
											</div>
										</a>
										<div class="author-info">
											<div class="icon-with-title">
												<i class="ri-user-line"></i>
												<p class="pera">By Admin</p>
											</div>
											<div class="icon-with-title">
												<i class="ri-message-2-line"></i>
												<p class="pera">12 Comments</p>
											</div>
										</div>
										<div class="news-content">
											<a href="blog-details.html">
												<h4 class="news-title line-clamp-2">Comfortable armchair in a room decorated
												</h4>
											</a>
										</div>
										<a href="blog-details.html" class="read-more-text">Read More <svg
												xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10"
												fill="none">
												<path
													d="M12.6227 4.38164C12.5587 4.38164 12.4989 4.38164 12.4349 4.38164C8.56302 4.38164 4.69114 4.38164 0.819254 4.38164C0.7168 4.38164 0.614347 4.37773 0.516163 4.40117C0.195996 4.46758 -0.0302552 4.76447 0.00389589 5.05746C0.0423159 5.37779 0.302718 5.60827 0.644229 5.6278C0.712532 5.63171 0.780834 5.63171 0.853405 5.63171C4.71248 5.63171 8.57583 5.63171 12.4349 5.63171C12.4989 5.63171 12.5587 5.63171 12.6654 5.63171C12.5971 5.69812 12.5587 5.73719 12.516 5.77625C11.3805 6.81928 10.2407 7.86231 9.10517 8.90534C8.82342 9.16317 8.79354 9.51866 9.0326 9.77648C9.27166 10.0382 9.68574 10.0773 9.98029 9.86633C10.0272 9.83508 10.0657 9.79602 10.1084 9.75695C11.6494 8.34671 13.1905 6.93257 14.7273 5.51842C15.0987 5.17856 15.0987 4.8387 14.7273 4.49883C13.1777 3.07687 11.6238 1.65491 10.0742 0.229051C9.8693 0.0415392 9.63878 -0.0483093 9.35276 0.0259132C8.88319 0.147015 8.70389 0.670483 9.00698 1.01425C9.0454 1.06113 9.09236 1.10019 9.13932 1.14317C10.2663 2.17448 11.389 3.20969 12.5203 4.241C12.563 4.28007 12.6185 4.2996 12.6654 4.33085C12.6483 4.34257 12.6355 4.3621 12.6227 4.38164Z"
													fill="#696969" />
											</svg>
										</a>
									</div>
								</div>
								<div class="swiper-slide">
									<div class="news-card">
										<a href="blog-details.html">
											<div class="news-img zoomImg">
												<img src="assets/images/news/news-3.png" alt="img"/>
												<div class="news-badge">
													<span class="date">
														04 April 2024
													</span>
												</div>
											</div>
										</a>
										<div class="author-info">
											<div class="icon-with-title">
												<i class="ri-user-line"></i>
												<p class="pera">By Admin</p>
											</div>
											<div class="icon-with-title">
												<i class="ri-message-2-line"></i>
												<p class="pera">12 Comments</p>
											</div>
										</div>
										<div class="news-content">
											<a href="blog-details.html">
												<h4 class="news-title line-clamp-2">Cafe with coffee tables as sofas plants and
													shelves</h4>
											</a>
										</div>
										<a href="blog-details.html" class="read-more-text">Read More <svg
												xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10"
												fill="none">
												<path
													d="M12.6227 4.38164C12.5587 4.38164 12.4989 4.38164 12.4349 4.38164C8.56302 4.38164 4.69114 4.38164 0.819254 4.38164C0.7168 4.38164 0.614347 4.37773 0.516163 4.40117C0.195996 4.46758 -0.0302552 4.76447 0.00389589 5.05746C0.0423159 5.37779 0.302718 5.60827 0.644229 5.6278C0.712532 5.63171 0.780834 5.63171 0.853405 5.63171C4.71248 5.63171 8.57583 5.63171 12.4349 5.63171C12.4989 5.63171 12.5587 5.63171 12.6654 5.63171C12.5971 5.69812 12.5587 5.73719 12.516 5.77625C11.3805 6.81928 10.2407 7.86231 9.10517 8.90534C8.82342 9.16317 8.79354 9.51866 9.0326 9.77648C9.27166 10.0382 9.68574 10.0773 9.98029 9.86633C10.0272 9.83508 10.0657 9.79602 10.1084 9.75695C11.6494 8.34671 13.1905 6.93257 14.7273 5.51842C15.0987 5.17856 15.0987 4.8387 14.7273 4.49883C13.1777 3.07687 11.6238 1.65491 10.0742 0.229051C9.8693 0.0415392 9.63878 -0.0483093 9.35276 0.0259132C8.88319 0.147015 8.70389 0.670483 9.00698 1.01425C9.0454 1.06113 9.09236 1.10019 9.13932 1.14317C10.2663 2.17448 11.389 3.20969 12.5203 4.241C12.563 4.28007 12.6185 4.2996 12.6654 4.33085C12.6483 4.34257 12.6355 4.3621 12.6227 4.38164Z"
													fill="#696969" />
											</svg>
										</a>
									</div>
								</div>
							</div>
							<div class="swiper-button-next swiper-common-btn"><i class="ri-arrow-right-s-line"></i></div>
							<div class="swiper-button-prev swiper-common-btn"><i class="ri-arrow-left-s-line"></i></div>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!-- End-of best news--> */}

        <Footer/>
    </main>
  )
}

export default Home2