import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Link } from "react-router-dom";

const Register = () => {
  return (
    <>
      <Header />
      <main>
        {/* <!-- Breadcrumbs S t a r t --> */}
        <section class="breadcrumb-section breadcrumb-bg">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcrumb-text">
                  <h1 class="title wow fadeInUp" data-wow-delay="0.1s">
                    Register
                  </h1>
                  <nav
                    aria-label="breadcrumb"
                    class="breadcrumb-nav wow fadeInUp"
                    data-wow-delay="0.0s"
                  >
                    <ul class="breadcrumb listing">
                      <li class="breadcrumb-item single-list">
                        <Link to={"/home"} className="single">Home</Link>
                      </li>
                      <li
                        class="breadcrumb-item single-list"
                        aria-current="page"
                      >
                        <a href="javascript:void(0)" class="single active">
                          Register
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End-of Breadcrumbs--> */}

        {/* <!-- Login area S t a r t  --> */}
        <div class="login-area section-padding">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10">
                <div class="login-card">
                  {/* <!-- Form --> */}
                  <form action="#" method="POST">
                    <div class="contact-form mb-24">
                      <label class="contact-label">Name </label>
                      <input
                        class="form-control contact-input"
                        type="text"
                        placeholder="Enter Your Name"
                      />
                    </div>
                    <div class="contact-form mb-24">
                      <label class="contact-label">Email </label>
                      <input
                        class="form-control contact-input"
                        type="email"
                        placeholder="Email"
                      />
                    </div>

                    {/* <!-- Password --> */}
                    <div class="position-relative contact-form mb-24">
                      <label class="contact-label">Enter Password</label>
                      <input
                        type="password"
                        class="form-control contact-input password-input"
                        id="txtPasswordLogin"
                        placeholder="Enter Password"
                      />
                      <i class="toggle-password ri-eye-line"></i>
                    </div>
                    {/* <!-- Password --> */}
                    <div class="position-relative contact-form mb-24">
                      <label class="contact-label">Confirm Password</label>
                      <input
                        type="password"
                        class="form-control contact-input password-input"
                        id="txtPasswordLogin2"
                        placeholder="Confirm Password"
                      />
                      <i class="toggle-password ri-eye-line"></i>
                    </div>

                    <a
                      href="javascript:void(0)"
                      class="btn-primary-fill justify-content-center w-100"
                    >
                      <span class="d-flex justify-content-center gap-6">
                        <span>Register</span>
                      </span>
                    </a>
                  </form>

                  <div class="login-footer">
                    <div class="create-account">
                      <p>
                        Already have an account?
                        <a href="login.html">
                          <span class="text-primary">Login</span>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--/ End-of Login --> */}
      </main>
      <Footer />
    </>
  );
};

export default Register;
